<template>
  <div
    v-show="thumbnails.length"
  >
    <v-carousel
      v-model="selectedThumbnail"
      hide-delimiters
      cycle
      height="auto"
    >
      <v-carousel-item v-for="thumbnail in thumbnails" :key="thumbnail.id">
        <v-img
          :src="thumbnail.url"
          :aspect-ratio="16/9"
          contain
        />
      </v-carousel-item>
    </v-carousel>
    <v-slide-group
      v-model="selectedThumbnail"
      class="pa-4"
      mandatory center-active show-arrows
    >
      <v-slide-item
        v-for="thumbnail in thumbnails"
        :key="thumbnail.id"
        v-slot="{ active, toggle }"
      >
        <v-sheet
          @click="toggle"
          class="ma-4"
          :class="{activeTile: active, nonActiveTile: !active }"
          elevation="4"
        >
          <v-row
            class="fill-height ma-0"
            align="center" justify="center"
          >
            <v-img 
              :src="thumbnail.url"
              aspect-ratio=1
              class="ma-0"
              contain
            />
          </v-row>
        </v-sheet>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>
<script>

export default{
  name: 'EventThumbnail',
  props:{
    eventId: String
  },
  data(){
    return{
      thumbnails: [],
      selectedThumbnail: 0
    }
  },
  async mounted(){
    this.thumbnails = await this.wc.get('event_thumbnail', `w/event_id,${this.eventId}`).catch((err) => {
      console.log(err)
    })
  }
}
</script>
<style scoped>
.activeTile{
  height: 100px;
  width: 100px;
}
.nonActiveTile{
  height: 60px;
  width: 60px;
}
>>>.v-slide-group__content{
  justify-content: center;
  align-items: center;
}
</style>
