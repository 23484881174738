<template>
  <v-dialog 
    v-model="isModalShow" 
    max-width="350" 
    persistent
    >
    <v-card 
      class="mx-auto"
      max-width="600" 
    >
      <v-card-title>パスワードをお忘れの方</v-card-title>
      <v-card-text class="pb-0">
        購入時に利用したメールアドレスを入力してください。
        <v-text-field 
          v-model="mailAddress"
          label="メールアドレス" 
          max-width="300"
          hide-details="auto"
          :rules="config.validation.mailRules"
          ref="textField"
        />
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions class="mb-5">
        <v-row justify="center">
          <v-btn
            class="mr-3"
            color="error" 
            @click="clearAddress(); $emit('cancel')">
            キャンセル 
          </v-btn>
          <v-btn 
            color="success" 
            @click="sendReset">
            パスワード設定メールを送る
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    isModalShow : Boolean,
    eventId: String
  },
  data(){
    return {
      errorMessage : '',
      mailAddress : '',
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    
    clearAddress(){
      this.$refs.textField.resetValidation();
      this.mailAddress = ''
      this.errorMessage = ''
    }, 
    async sendReset(){
      if(!this.eventId || !this.$refs.form.validate()) return
      const purchase = await this.wc.get('v_purchase', `w/event_id,${this.eventId}:mail,${this.mailAddress}`).catch((err) => {
        console.log(err)
      })
      if(!purchase.length){
        this.errorMessage = "指定のメールアドレスでは購入履歴がありません";
        return;
      }
      await this.wc.post('forgot', [{event_id: this.eventId, mail: this.mailAddress}]).catch((err) => {
        console.log(err)
      })
      this.openSnackbar('ご確認のメールをお送りいたしました。\nメールが届かない場合には、お手数ですが再度お試しください。')
      this.$emit('success')
    },
  },
}
</script>
