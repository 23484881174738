<template>
  <v-dialog 
    v-model="isModalShow" 
    max-width="550" 
    @click:outside="$emit('close')"
    >
    <v-card 
      max-width="700" 
      class="mx-auto"
      >

      <!-- v-stepper -->
      <v-stepper
        v-model="stepper"
        vertical
        non-linear
        >

        <!-- step1 -->
        <v-stepper-step editable step="1" >
          「購入手続き」のボタンをクリック
        </v-stepper-step>

        <v-stepper-content step="1">
          イベントページから、購入したいチケットをクリックします。
          <v-img src="@/assets/images/konyu_1.png" max-width="200"> </v-img>
        </v-stepper-content>

        <!-- step2 -->
        <v-stepper-step editable step="2" >
          メールアドレスを入力
          <small> ※購入用のメールを送信 </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          購入手続きを実施する「メールアドレス」を入力し、「利用規約に同意する」にチェックをいれ「メールを送信」をクリックします。
          <!--
            <v-img src="@/assets/images/konyu_2.png" max-width="200"> </v-img>
          -->
        </v-stepper-content>

        <!-- step3 -->
        <v-stepper-step editable step="3" >
          チケットを購入
        </v-stepper-step>
        <v-stepper-content step="3">
          届いたメールのURLから決済手続きを行います。
        </v-stepper-content>

        <!-- step4 -->
        <v-stepper-step editable step="4" >
          購入者用のURLを発行
        </v-stepper-step>
        <v-stepper-content step="4">
          決済完了後に購入者用のURLが届きますので、そちらからライブをお楽しみいただけます。 
          <p> （画面から「チケットの購入」が非表示になっていればライブを見られる状態です。表示されない場合は、「購入済みチケットの認証」から再認証をお願いします。） </p>
        </v-stepper-content>
      
      </v-stepper>

      <v-card-actions>
        <v-spacer />
        <v-btn 
          color="primary" 
          @click="$emit('close')"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  
  props: {
    isModalShow : Boolean,
  },

  data(){
    return {
      stepper : 1,
    }
  },
}
</script>
