<template>
  <div>
    <StoneHeader />
    <v-container fluid>
      <v-row>
        <!-- チケット未購入 -->
        <template v-if="!getBuy">
          <v-col md="9" sm="12" cols="12">
            <v-card>
              <event-thumbnail :eventId="eventId" />
              <event-detail :event="event" />
              <v-divider />
              <event-content-list :eventId="eventId" />
            </v-card>
            <event-contact
              v-if="!event.is_ended"
              :event="event"
            />
          </v-col>
          <v-col md="3" sm="12" cols="12">
            <event-item
              @checkDuplicate="$emit('checkDuplicate')"
              :event="event"
              :isMobile="isMobile"
            />
          </v-col>
        </template>
        <!-- チケット購入済み -->
        <template v-else>
          <v-col md="12" sm="12" cols="12">
            <v-card>
              <event-thumbnail :eventId="eventId" />
              <event-detail :event="event" />
              <v-divider />
              <event-content-list :eventId="eventId" />
              <event-contact
                v-if="!event.is_ended"
                :event="event"
              />
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <StoneFooter :isMobile="isMobile" />
  </div>
</template>
<script>
import StoneHeader from '@/components/organisms/StoneHeader'
import StoneFooter from '@/components/organisms/StoneFooter'
import EventThumbnail from '@/components/organisms/EventThumbnail'
import EventDetail    from '@/components/organisms/EventDetail'
import EventContentList  from '@/components/organisms/EventContentList'
import EventItem      from '@/components/organisms/EventItem'
import EventContact  from '@/components/organisms/EventContact'

export default{
  name: 'Event',

  components: {
    StoneHeader,
    StoneFooter,
    EventThumbnail,
    EventDetail,
    EventContentList,
    EventItem,
    EventContact
  },
  props:{
    event: Object,
    eventId: String,
    getBuy: Boolean
  },
  data(){
    return{
      isMobile: false
    }
  },
  provide: {
    isEmbedded: false // ModalItemBuyで使用
  },
  async created(){
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed(){
    window.removeEventListener('resize', this.handleResize)
  },
  methods:{
    handleResize(){
      this.isMobile = window.innerWidth < 960 ? true : false
    }
  }
}
</script>
