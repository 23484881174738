<template> 
  <v-row align="center" class="ma-0 buy-btn-container">
    <!-- チケット購入 -->
    <v-col cols="12">
      <v-btn
        class="white--text"
        color="red"
        block
        large
        @click="isModalShow = true;"
        >
        チケット購入
      </v-btn>
    </v-col>

    <!-- modal-item-buy-mobile component -->
    <modal-item-buy-mobile
      :isModalShow="isModalShow"
      :event="event"
      @cancel="isModalShow = false;"
      @hideModal="isModalShow = false;"
      @checkDuplicate="$emit('checkDuplicate')"
    />
  </v-row>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ModalItemBuyMobile from '@/components/molecules/ModalItemBuyMobile.vue'

export default {
  name: 'EventItemMobile',
  components: {
    ModalItemBuyMobile,
  },
  props: {
    event: Object
  },
  data(){
    return{
      isModalShow : false,
    }
  },
}
</script>
<style scoped>
.buy-btn-container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

</style>
