import moment from 'moment'

export default {
  dispDate(dt){
    // DATE型 => YYYY年mm月dd日（曜日）hh時MM分
    const baseDate = new Date(dt.replace(/-/g,"/"))
    const year = baseDate.getFullYear();
    const month = baseDate.getMonth() + 1;
    const date = ('0' + baseDate.getDate()).slice(-2);
    const hour = ('0' + baseDate.getHours()).slice(-2);
    const minuts = ('0' + baseDate.getMinutes()).slice(-2);

    const day_arr = ['日', '月', '火', '水', '木', '金', '土']
    const day = day_arr[baseDate.getDay()];

    const shapingDate = year + '年' + month + '月' + date + '日' + '（'+ day + '）' + hour + '時' + minuts + '分';

    return shapingDate
  },
  generateCountDown(dt){
    if(!dt) return
    const beginAt   = moment(dt),
          duration  = moment.duration(beginAt.diff(moment()))

    const day       = Math.floor(duration.asDays()),
          hour      = duration.hours(),
          minute    = duration.minutes(),
          second    = duration.seconds()

    // 開始時間前
    let message = 'あと'
    if(day >= 1)    message += `${day}日と`
    if(hour >= 1)   message += `${hour}時間`
    if(minute >= 1) message += `${minute}分`
    if(second >= 1) message += `${second}秒`

    return message
  }
}
