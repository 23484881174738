<template> 
  <v-container>
    <v-col 
      md="9" sm="12" 
      align="center"
      class="mx-auto"
    >
      <v-btn
        class="ma-1"
        color="primary"
        outlined
        @click="isModalShow = true;"
      >
        イベントに対する問い合わせ
      </v-btn>
    </v-col>
    <modal-contact
      :event="event"
      :isShow="isModalShow"
      @hideModal="isModalShow = false;"
    />
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ModalContact from '@/components/molecules/ModalContact.vue'

export default {
  name: 'EventContact',
  components: {
    ModalContact,
  },
  props: {
    event: Object,
  },
  data(){
    return{
      isModalShow : false,
    }
  },
}
</script>

<style>
</style>
