<template> 
  <v-container>
    <!--
    <v-card-subtitle>{{ countDownMessage }}</v-card-subtitle>
    -->
    <v-card-title>{{ event.title }}</v-card-title>
    <v-card-text>
      {{ formatDatetime(event.begin_at) }}  - {{ formatDatetime(event.end_at) }}
    </v-card-text>
    <v-card-text class="text-pre-wrap">{{ event.summary }}</v-card-text>
  </v-container>
</template>

<script>
import moment from 'moment'
import datetimeLib from '@/lib/datetime'

export default {
  name: 'EventDetail',
  data(){
    return{
      timer: null,
      countDownMessage: ''
    }
  },
  props: {
    event: Object,
  },
  mounted(){
    this.timer = setInterval(this.updateCountDown, 1000)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods: {
    formatDatetime(dt){
      if(!dt) return
      return datetimeLib.dispDate(dt)
    },
    updateCountDown(){
      if(!this.event) return

      let message = datetimeLib.generateCountDown(this.event.begin_at)
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if(moment(this.event.begin_at).isSameOrBefore(now)){
        message = '開始までしばらくお待ちください'
      }
      this.countDownMessage = message
    }
  }
}
</script>

<style>

</style>
