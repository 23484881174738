<template> 
  <div style="height:100%">  
    <!-- pc -->  
    <template v-if="!isMobile">
      <event-item-list
        :event="event"
        @checkDuplicate="$emit('checkDuplicate')"
      />
    </template>
    <!-- mobile -->  
    <template v-else>
      <event-item-mobile :event="event" />
    </template>
  </div>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import EventItemList from '@/components/organisms/EventItemList.vue'
import EventItemMobile from '@/components/organisms/EventItemMobile.vue'

export default {
  name: 'EventItem',
  components: {
    EventItemList,
    EventItemMobile,
  },
  props: {
    event: Object,
    isMobile: Boolean,
  },
}
</script>

<style scoped> 

</style>
