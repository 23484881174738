<template>
  <v-col class="item-container">
    <!-- Modal -->
    <!-- 購入方法 -->
    <modal-item-buy-info
      :isModalShow="isModalInfoShow"
      @close="isModalInfoShow = false;"
    />

    <!-- 認証 -->
    <modal-item-auth
      :isModalShow="isModalAuthShow"
      @cancel="isModalAuthShow = false;"
      @success="isModalAuthShow = false; $emit('checkDuplicate');"
      @forgot="isModalAuthShow = false; isModalForgotShow = true;"
    />

    <!-- パスワード忘れ -->
    <modal-item-forgot
      :isModalShow="isModalForgotShow"
      @success="isModalForgotShow = false;"
      @cancel="isModalForgotShow = false;"
    />

    <!-- 購入 -->
    <modal-item-buy
      :event="event"
      :item="selectedItem"
      :isModalShow="isModalBuyShow"
      @cancel="isModalBuyShow = false;"
      @purchased="isModalBuyShow = false; $emit('checkDuplicate');"
      @sentPurchaseMail="isModalBuyShow = false; $emit('hideModal')" 
    />
    <!--  Modalここまで -->
  <v-banner
    single-line
    v-if="filterItems.length"
    class="pa-0 mb-2 primary--text ticket-description"
  >
    購入するチケットをタップしてください
    <v-btn
      @click="isModalInfoShow = true;"
      icon
    >
      <v-icon small>mdi-help-circle-outline</v-icon>
    </v-btn>
  </v-banner>
    <!-- チケット一覧 -->
    <div
      v-for="item in filterItems"
      :key="item.id"
      class="pb-3"
      >
      <v-hover v-slot="{ hover }">
        <v-card
          class="ticket"
          @click="selectedItem = item; isModalBuyShow = true"
          :elevation="hover ? 12 : 6"
          shaped
          color="primary"
          dark
          :disabled="event.event_status_property_name == 'done'"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                <font size="2">{{ item.name }} </font>
              </v-list-item-title>
              <v-divider />
              <v-list-item-subtitle>
                <font size="1"> {{ item.summary }} </font>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text align="right">
            <div class="wave-anime">
              <v-icon class="ticket-pointer">
                mdi-cursor-pointer
              </v-icon>
            </div>
            <font size="8" class="white--text">
            ¥{{ item.price | local }}
            </font>
          <!--
          <v-card-actions>
            <v-btn
              label
              color="error"
              @click="selectedItem = item; isModalBuyShow = true"
              >
              購入手続き
            </v-btn>
          </v-card-actions>
          -->
          </v-card-text>
        </v-card>
      </v-hover>
    </div>
    <div class="text-center">
      <v-btn
        class="my-3"
        rounded
        outlined
        small
        color="primary"
        @click="isModalAuthShow = true;"
      >
        購入済みチケットの認証
      </v-btn>
    </div>
  </v-col>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import ModalItemAuth from '@/components/molecules/ModalItemAuth.vue'
import ModalItemBuy from '@/components/molecules/ModalItemBuy.vue'
import ModalItemBuyInfo from '@/components/molecules/ModalItemBuyInfo.vue'
import ModalItemForgot from '@/components/molecules/ModalItemForgot.vue'

export default {
  name: 'EventItemList',
  components: {
    ModalItemAuth,
    ModalItemBuy,
    ModalItemBuyInfo,
    ModalItemForgot
  },
  props: {
    event: Object,
  },
  data(){
    return{
      items: [],
      selectedItem : {},
      isModalBuyShow : false,
      isModalAuthShow : false,
      isModalForgotShow : false,
      isModalInfoShow : false,
      isEventEnded : false
    }
  },
  computed:{
    filterItems(){
      return this.items.filter(v => v.price != 0)
    }
  },
  async mounted(){
    this.loadItems()
  },
  watch:{
    event: function(){
      this.loadItems()
    }
  },
  methods:{
    async loadItems(){
      this.items = await this.wc.get('item', `w/event_id,${this.event.id}:is_enabled,1`).catch((err) => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
@media screen and (min-width: 960px) { 
  .item-container {
    position: -webkit-sticky; /* safari用 */
    position: sticky;
    margin-bottom: 0;
    top: 0px;
    z-index: 1;
  }
}
.headline {
  overflow: ellipsis;
  white-space: nowrap;
}
.vertical-center{
  display: flex;
  align-items: center;
}
>>>.ticket-description.v-banner .v-banner__text{
  white-space: pre-wrap;
}
.ticket{
  border: 3px solid #000;
}
.ticket-pointer{
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}
.wave-anime{
  position: absolute;
  display: inline-block;
  left: 25px;
  width: 20px;
  height: 20px;
  bottom: 25px;
  transition: all .6s;
  z-index: 99;
}
.wave-anime::after,
.wave-anime::before {
  content: "";
  position: absolute;
  left: -50%;
  top: -40%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 200%;
  height: 200%;
  border-radius: 50%;
  animation: 1.5s circleanime linear infinite;
}
.wave-anime::before{
  animation-delay: .5s;
}
@keyframes circleanime{
  0%{
    transform: scale(0.68);
  }
  100%{
    transform: scale(1.2);
    opacity: 0;
  }
}
</style>
