<template>
  <v-dialog
    v-model="isModalShow"
    persistent
    max-width="290"
    >
    <v-card>
      <v-card-title>ライブ中です。</v-card-title>
      <v-card-text>
        既に配信は開始しています。このままチケット購入を続行しますか？
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="second"
          @click="$emit('cancel')"
          >
          キャンセル
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          @click="$emit('continue')"
          >
          購入手続きをする
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isModalShow : Boolean,
  },
}
</script>
