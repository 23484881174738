<template>
  <v-card flat>
    <v-card-title class="justify-center">
      配信コンテンツ
    </v-card-title>
    <v-card-text>
      <p
        v-if="!getBuy"
        class="text-caption text-center"
      >
        ※チケット購入後にコンテンツ画面に遷移できるようになります
      </p>
      <v-hover 
        v-for="content in contents" :key="content.id"
        v-slot="{ hover }"
      >
        <v-card 
          @click="toContent(content)"
          :disabled="!getBuy"
          :elevation="hover ? 12 : 2 "
          class="ma-sm-0 ma-md-9 mb-6"
          :class="{ 'on-hover' : hover }"
        >
          <v-row>
            <v-col sm="12" md="3" cols="12" class="py-0">
              <v-img
                contain
                height="100%"
                :src="content.thumbnail.url"
              />
            </v-col>
            <v-col sm="12" md="9" cols="12">
              <v-card-title class="text-body-1">
                <v-row>
                  <v-col class="flex-grow-1">
                    {{ content.title }}
                  </v-col>
                  <v-col
                    class="align-end"
                    cols="12" md="2"
                    style="min-width:200px;"
                  >
                    <v-chip 
                      label
                      :color="statusColor(content.content_status_id)"
                    >
                      {{ content.content_status_name }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <span class="text-caption">
                  {{ formatDatetime(content.begin_at) }}&nbsp;-&nbsp;{{ formatDatetime(content.end_at) }}
                </span>
                <ul>
                  <li v-for="artist in content.artists" :key="artist.id">
                    {{ artist.name }}
                  </li>
                </ul>
                <p>
                {{ content.summary }}
                </p>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import datetimeLib from '@/lib/datetime'

export default{
  name: 'EventContentList',

  props:{
    eventId: String
  },

  data(){
    return{
      contents: [],
      selectedContent: 0
    }
  },
  computed:{
    ...mapGetters(['getBuy', 'getHash'])
  },
  async mounted(){
    await this.loadContent()
  },
  watch:{
    $route: async function (){
      await this.loadContent()
    }
  },
  methods:{
    async loadContent(){
      const eventContents = await this.wc.get('event_content', `w/event_id,${this.eventId}`).catch((err) => {
        console.log(err)
      })
      if(!eventContents) return

      const contents = []
      for(const eventContent of eventContents){
        if(this.$route.params.content_id && this.$route.params.content_id == eventContent.content_id) continue
        const [content] = await this.wc.get('v_content', `w/id,${eventContent.content_id}:is_published,1`).catch((err) => {
          console.log(err)
        })
        if(!content) continue

        // サムネイル
        const thumbnails = await this.wc.get('content_thumbnail', `w/content_id,${eventContent.content_id}`).catch((err) => {
          console.log(err)
        })
        content.thumbnail = thumbnails[0] || [{url: ''}]

        // アーティスト
        content.artists = await this.wc.get('artist', `w/content_id,${eventContent.content_id}`).catch((err) => {
          console.log(err)
        })
        
        contents.push(content)
      }
      this.contents = contents

    },
    formatDatetime(dt){
      if(!dt) return
      return datetimeLib.dispDate(dt)
    },
    toContent(content){  
      this.$router.push({path: `/event/${this.eventId}/content/${content.id}`})
    },
    statusColor(status_id){
      if(!status_id) return
      const colors = {
        1 : 'primary',  // 未配信
        2 : 'error',  // 配信中
        3 : 'secondary',// アーカイブ準備中
        4 : 'success',  // アーカイブ配信中
        5 : 'grey'     // 完了
      }
      return colors[status_id]
    }
  }
}
</script>
<style scoped>
>>>.v-slide-group__content{
  justify-content: center;
  align-items: center;
}
.activeTile{
  height: 100px;
  width: 100px;
}
.nonActiveTile{
  height : 60px;
  width : 60px;
}

.v-card {
  transition: opacity .2s ease-in-out;
}
.v-card:not(.on-hover){
  opacity: 0.9;
}
</style>
