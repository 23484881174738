<template>
  <div>
    <v-row justify="center">
      <v-dialog 
        v-model="isShow" 
        max-width="350" 
        persistent
      >
        <v-card 
          max-width="600" 
          class="mx-auto"
        >
          <v-card-text>
            <v-form ref="form">
              <v-card-title> お問い合わせフォーム </v-card-title>
              <v-card-subtitle>  {{ event.title }} </v-card-subtitle>
              <v-text-field 
                label="名前" 
                max-width="300" 
                v-model="name"
                hide-details="auto"
                :rules="nameRule"
              />
              <v-text-field 
                label="メールアドレス"
                max-width="300"
                v-model="mail"
                hide-details="auto"
                :rules="mailRule"
              />
              <v-textarea 
                label="お問合せ内容" 
                v-model="content"
                ref="contactText"
                :rules="messageRule"
              />
              <v-card-actions>
                <v-spacer />
                <v-btn 
                  color="error" 
                  @click="$emit('hideModal')"
                > 
                  キャンセル 
                </v-btn>
                <v-btn 
                  color="success" 
                  @click="sendMail" 
                > 
                  送信 
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    event : {},
    isShow : Boolean,
  },
  data(){
    return {
      mail : '',
      name : '',
      content : '',
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async sendMail() {
      if(!this.$refs.form.validate()) return 
      const [user] = await this.wc.get('user', `w/id,${this.event.user_id}`).catch((err) => {
        return err;
      });
      const postData = [{
        "name"    : this.name,
        "mail"    : this.mail,
        "content" : this.content,
        "managerMail"    : user.mail,
        "eventTitle"  : this.event.title,
      }]
      await this.wc.post('contact', postData).catch((err) => {
        console.log(err)
      })
      this.mail = ''
      this.name = ''
      this.content = ''
      this.openSnackbar('お問合せを送信しました')

      this.$emit('hideModal')
    },
  },
  computed:{
    nameRule(){
      return [
        this.config.validation.required,
        v => this.config.validation.maxStr(v, 100)
      ]
    },
    mailRule(){
      return [
        this.config.validation.required,
        this.config.validation.mail,
        v => this.config.validation.maxStr(v, 200)
      ]
    },
    messageRule(){
      return [
        this.config.validation.required,
        v => this.config.validation.maxStr(v, 10000)
      ]
    }
  }
}
</script>
