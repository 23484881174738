<template>
  <v-dialog
    v-model="isModalShow"
    persistent
    max-width="95%"
  >
    <v-card
      max-width="600"
      class="mx-auto"
    >
      <v-card-text>
        <event-item-list
          :event="event"
          @checkDuplicate="isModalShow = false; $emit('checkDuplicate')" 
          @hideModal="$emit('hideModal');"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="error"
          @click="$emit('cancel')"
        >
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EventItemList from '@/components/organisms/EventItemList.vue'

export default {
  components:{
    EventItemList,
  },
  props: {
    isModalShow : Boolean,
    event: Object
  },
}
</script>
