<template>
  <v-dialog 
    v-model="isModalShow" 
    max-width="400" 
    persistent
    >
    <v-card 
      class="mx-auto"
      >
      <v-card-title> 購入済みチケットの認証 </v-card-title>
      <v-card-text>
        <p class="text-caption">
          購入時に登録したメールアドレスを入力してください。
        </p>
<!--        購入時に利用したメールアドレス・パスワードを入力してください。 -->
        <v-text-field 
          v-model="mailAddress"
          label="メールアドレス" 
          prepend-icon="mdi-email-outline"
          max-width="300"
          hide-details="auto"
          :rules="config.validation.mailRules"
          ref="textField"
        />
<!--
        <v-text-field
          v-model="password"
          label="パスワード"
          :append-icon="isPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          autocomplete
          :type="isPasswordShow ? 'text' : 'password'"
          @click:append="isPasswordShow = !isPasswordShow"
        />
-->
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions class="pb-5 justify-center">
        <v-btn
          class="mr-3"
          color="error" 
          @click="clearAddress(); $emit('cancel')">
          キャンセル 
        </v-btn>
        <v-btn 
          color="success" 
          @click="AuthClick">
          認証
        </v-btn>
      </v-card-actions>
<!--
      <v-card-text class="text-center">
        <span class="text-caption">
          パスワードをお忘れの方は<a @click="$emit('forgot')">こちら</a>
        </span>
      </v-card-text>
-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mixinPurchase from '@/mixins/purchase'

export default {
  props: {
    isModalShow : Boolean,
  },
  mixins: [mixinPurchase],
  inject: ['connectWebSocket'], // Event.vue
  data(){
    return {
      errorMessage : '',
      mailAddress : '',
//      password: '',
//      isPasswordShow: false
    }
  },
  computed: {
    ...mapGetters(['getHash'])
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    
    clearAddress(){
      this.$refs.textField.resetValidation();
      this.mailAddress = ''
//      this.password = ''
      this.errorMessage = ''
    }, 
    async AuthClick(){
//      const [resPurchase] = await this.$_getPurchase(this.$route.params.id, this.mailAddress, this.password) //mixin
      const resPurchase = await this.$_getPurchase(this.$route.params.id, this.mailAddress) //mixin
      const purchasedData = resPurchase.filter(v => v.is_purchased).length
      if(purchasedData == 0){
        this.errorMessage = "メールアドレスが一致しません。";
        return;
      }
      this.$_setPurchase(this.$route.params.id, this.mailAddress) // mixin
      await this.wc.post('checkDuplicate', [{'event_id':this.$route.params.id, 'hash':this.getHash}]).catch((err) => {
        console.log(err)
      })
      this.openSnackbar('認証しました')
      this.connectWebSocket()
      this.$emit('success')
    },
  },
}
</script>
